body {
    padding-bottom: 0;
}

.container {
    padding-left: $spacer * 2 !important;
    padding-right: $spacer * 2 !important;
}

.card {
    margin-bottom: $spacer * 2;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {

    padding-left: $spacer * 1;
    padding-right: $spacer * 1;

}

.row {
    margin-left: -$spacer * 1;
    margin-right: -$spacer * 1;
}




::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: $info;
    background: $gray-500;

    // background: linear-gradient(to bottom, $gray-600);
}

@media(min-width: 576px) {
    .container {
        max-width: 100%;
    }
}


@media(min-width: 768px) {
    .container {
        max-width: 100%;
    }
}

@media(min-width: 860px) {
    .container {
        width: 860px;
        max-width: 100%;
        padding-left: $spacer;
        padding-right: $spacer;
    }
}

// .container-fluid {
//     width: 1280px;
//     max-width: calc(100% - 20px);

//     &.full {
//         width: auto;
//     }
// }

// .bg-full {
//     .container-fluid {
//         max-width: calc(100% - 20px);
//         width: auto;
//     }
// }
@media(min-width: 768px) {
    .main-content {
        .container-fluid {
            padding-left: $spacer * 1.5 !important;
            padding-right: $spacer * 1.5 !important;
        }
    }
}







.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

.rbc-calendar {

    .rbc-btn-group {
        width: 300px;
    }

    .rbc-toolbar {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;
        margin-top: $card-spacer-y !important;
    }
    
    .rbc-agenda-empty {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;
        text-align: center;
        font-weight: bold;
        border-top: solid 1px $gray-200;
        padding-top: 20px;
    }

    .rbc-event {
        background: $primary;
    }

    // .rbc-event-label {
    //     display: none;
    // }

}

.vertical-timeline {

    &::before {
        background: rgba(0,0,0,.03);
    }

    .vertical-timeline-element-content-arrow {

        border-right: 7px solid rgba(0,0,0,.03) !important;
    }
    
    .vertical-timeline-element-content {
        background: rgba(0,0,0,.03)
    }

}



.tooltip-inner {
    // background: $gray-100;
    // background: linear-gradient(to bottom right, $gray-100, $gray-100);
    // color: $body-color !important;
    border-radius: 6px;
    font-size: $font-size-xs;
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    border: solid 1px darken($purple, 10%);
    background: $purple !important;
    padding-left: $spacer * 1;
    padding-right: $spacer * 1;
    // opacity: .9;
}

