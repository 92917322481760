.archk-workflow {

    // .archk-internal-sidebar-col-left {
    //     ul {
    //         padding-left: 0;

    //         li {
    //             list-style: none;
    //             padding-left: $spacer * 1.5;
    //             margin-bottom: $spacer * .5;
    //             cursor: pointer;

    //             &:hover {
    //                 border-left: solid 2px $gray-300;

    //             }

    //             &.active {
    //                 border-left: solid 2px $info;
    //             }
    //         }
    //     }
    // }

    .archk-workflow-sidebar {
        // position: fixed;
        // left: 0;
        // top: 80px;
        // bottom: 0;
        // width: 250px;
        // z-index: 1500;
        // background-color: white;
        // border-right: solid 1px $gray-300;

        .archk-workflow-sidebar-header {
            
        }
        .archk-workflow-sidebar-body {
            overflow: auto;
            max-height: calc(100vh - 136px);
            border-top: solid 1px $gray-200;
        }

        .archk-workflow-sidebar-spacer {
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
            padding-top: $spacer;
            padding-bottom: $spacer;
        }
        
        .archk-workflow-sidebar-spacer-vertical {
            padding-top: $spacer;
            padding-bottom: $spacer;
        }

        .archk-workflow-sidebar-step {

            &:hover {
                background: $info !important;

                p, i {
                    color: $white;
                }
            }

            .archk-workflow-sidebar-step-icons {
                i {
                    margin-right: $spacer / 2;
                    font-size: $font-size-sm;
                    display: inline-block;
                }
            }

        }

       

        .archk-workflow-sidebar-edit-blackout {
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1500;
            background-color: rgba(0,0,0,.5);
        }
        .archk-workflow-sidebar-edit-modal {
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            width: 1000px;
            max-width: 90%;
            z-index: 1600;
            background-color: white;
            border-left: solid 1px $gray-300;
            overflow: auto;
        }
    }

    .archk-workflow-save {
        position: fixed;
        top: 20px;
        right: 8px;
        z-index: 100;
    }

    .archk-workflow-main {
        margin-left: 250px;

        ul {

            position: relative;
            padding-left: 10px;
            margin-top: 10px;

            &:before {
                content: "";
                width: 46px;
                border-left: 1px dotted #8898aa;
                position: absolute;
                left: -3px;
                height: calc(100% - 10px);
                top: -15px;
            }

            li {
                list-style: none;
                list-style: none;
                padding-left: 20px;
                padding-bottom: 2px;
                position: relative;
                top: -13px;
                width: auto;

                &:before {
                    content: "";
                    width: 25px;
                    height: 0;
                    border-top: 1px dotted black;
                    position: absolute;
                    left: -12px;
                    top: 15px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 12px;
                    top: 10px;
                    width: 0;
                    height: 0;
                    border-top: 5px dotted transparent;
                    border-bottom: 5px dotted transparent;
                    border-left: 5px solid black;
                    clear: both;

                }

                &.archk-workflow-main-insert-step {
                    position: relative;
                    top: -9px;
                }

                .btn {
                    padding: .5rem !important;
                    height: auto;
                    font-size: 10px;
                    min-width: 0 !important;
                    line-height: 10px;
                    margin-top: 0px;
                }

                .archk-workflow-main-step-name {
                    position: relative;
                    top: 4px;
                }

            }


        }
    }

}