.archk-messaging {

    position: fixed;
    top: 92px;
    left: 200px;
    right: 0px;
    bottom: 0;
    width: 100%;

    a:hover {
        color: inherit;
    }

   
    .archk-messaging-spacer {
        padding-left: $spacer;
        padding-right: $spacer;
        padding-top: $spacer *.5;
        padding-bottom: $spacer *.5;
        border-bottom: solid 1px $white;
    }

    .archk-text-message-list-item {
        background: $white;
        border-left: solid 4px $gray-200;
        border-bottom: solid 1px $gray-400;

        transition: all .2s;
        cursor: pointer;
        &:hover {
            border-left: solid 4px $info;
            background: lighten($info, 40);
        }

        .archk-text-message-list-item-description {

            line-height: 12px;
        }
    }

    .archk-text-message-list-item-active {
        border-left: solid 4px $green !important;
        background: lighten($success, 40) !important;
    }

    .archk-text-message-list-item-read {
        background: $gray-200;
        border-left: solid 4px $gray-200;
    }

    .archk-text-message-attachment-wrapper {
        display: flex;
        flex-wrap: wrap;

        &.archk-support-ticket-email-attachment-wrapper {
            display: block;
        }
    }

    .archk-text-message-attachment {
        cursor: pointer;
        width: 150px;
        height: 150px;
        max-width: 100%;
        display: inline-block;
        position: relative;
        border: solid 1px $white;
        margin-bottom: $spacer * 2;
    }

    .archk-text-message-image {
      
        background-position: center;
        background-size: cover;

    }
    .archk-text-message-file {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .archk-messaging-sidebar-left {
        width: 250px;

        background: $white;
        position: absolute;
        left: 0;
        top: 62px;
        bottom: 0;
        z-index: 4;
        overflow: auto;
        // padding-bottom: 60px;

        .archk-messaging-sidebar-left-search {
            padding: 8px;
            position: absolute;
            right: 4px;
            top: 8px;
            cursor: pointer;
            background: $gray-200;
            border: solid 1px $gray-300;
            border-radius: 8px;

            &:hover {
                background: $white
            }
        }


       
        .archk-message-number {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            background-color: $purple;
            font-size: $font-size-sm;
            display: inline-block;
            margin-right: $spacer;
            color: $white;
            padding-top: 0px;
            position: relative;
            top: -3px;

            &.archk-message-number-call {
                background-color: $success;
            }
            &.archk-message-number-text {
                background-color: $info;
            }
    
        }
       
      
    }

    .archk-messaging-sidebar-right {
        width: 200px;
        background: $white;
        position: absolute;
        right: 200px;
        top: 62px;
        bottom: 0;
        z-index: 4;
        overflow: auto;

        .archk-attachment-name {
            line-height: 14px;
            font-size: 12px;
        }

        .archk-support-ticket-email-sidebar {
            .archk-text-message-attachment {
                width: 100%;
                height: 100px;

                margin-bottom: $spacer * 2;

                .display-1 {
                    font-size: 50px !important;
                }
            }   
        }

        .archk-text-message-attachment {
            width: 55px;
            height: 55px;



            .display-1 {
                font-size: 24px !important;
            }
           
        }
    }

    .archk-messaging-main-header {
        padding-left: $spacer * 2;
        padding-right: $spacer * 2;
        padding-top: $spacer;
        padding-bottom: $spacer;
        background: $white;
        // border-bottom: solid 1px $gray-400;
        border-top: solid 1px $gray-400;

        position: fixed;
        left: 200px;

        right: 000px;

        z-index: 8;
        top: 92px;

        .archk-messaging-main-icon {
            padding: 12px;
            border: solid 1px $gray-200;
            margin-left: $spacer * 1.5;
            border-radius: 100%;
            cursor: pointer;
            background: $gray-100;
            color: $gray-500;

            &:hover {
                background: $white;
                color: $success;
            }

        }

    }

    .archk-messaging-send {
        position: fixed;
        left: 450px;
        bottom: 0;
        right: 200px;
        background: $gray-200;
        padding: 16px 16px;
        z-index: 3;

        .archk-messaging-send-wrapper {
            background: white;
            border-radius: 0.4375rem;
            position: relative;

            .archk-messaging-send-attach {
                position: absolute;
                right: 60px;
                bottom: 7px;
                font-size: 16px;
                cursor: pointer;
            }
            .archk-messaging-template {
                position: absolute;
                right: 88px;
                bottom: 7px;
                font-size: 16px;
                cursor: pointer;
            }

            button {
                position: absolute;
                right: 0;
                bottom: 0;
                height: 30px;
                padding-left: 15px;
                padding-right: 15px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            input, textarea {
                background: inherit;
                border: none;
                border-radius: 0.4375rem;
                padding-right: 118px;
            }

        }

      

    }

    .archk-messaging-resolved {
        background: $gray-100;

        width: calc(100% - 650px);

        position: absolute;
        right: 200px;
        left: 250px;
        top: 62px;

        padding-top: 13px;
        padding-bottom: 13px;

        background: lighten($info, 10%) !important;
        border: solid 2px $info;

    }


    .archk-messaging-main {
        background: $gray-100;
        width: calc(100% - 650px);

        position: absolute;
        right: 200px;
        left: 250px;
        top: 62px;
        margin-bottom: 60px;
        bottom: 0;
        overflow: auto;

        &.archk-messaging-main-empty {
            margin-bottom: 0px;
            z-index: 5;
        }
        &.archk-messaging-main-no-response {
            margin-bottom: 0px;
            top: 113px;
            z-index: 5;
        }

        &.archk-messaging-main-tickets {
            margin-bottom: 0;
            padding-bottom: $spacer;
        }
       
        .archk-email-rendering {
            .archk-text-message {
                &.archk-text-message-incoming {
                    .archk-text-message-wrapper {
                        background: $white;
                        border: solid 1px $gray-200;
                        color: inherit;
                    }
                }
            }
        }
      
        .archk-text-message {

            padding: $spacer;
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
            text-align: left;

            .archk-text-message-wrapper {
                display: inline-block;
                max-width: 75%;
                text-align: left;
                border-radius: 0.4375rem;
                padding: $spacer $spacer * 2;

            }



            &.archk-text-message-incoming {
              
                .archk-text-message-wrapper {
                    background: lighten($info, 5);
                    color: $white;

                    border: solid 1px darken($info, 10);

                }
            }
          
            &.archk-text-message-outgoing {
                
                text-align: right;

                .archk-text-message-wrapper {
                    background: $white;
                    border: solid 1px $gray-200;

                }
            }


          

        }

    }
    
}

@include media-breakpoint-down(md) {

    .archk-messaging {
        left: 0;

        .archk-messaging-main-header {
            left: 0;
        }

        .archk-messaging-sidebar-right {    
            right: 0;
        }

        .archk-messaging-main {
            width: calc(100% - 450px);
        }

        .archk-messaging-send {
            left: 250px;
        }

    }

   
}