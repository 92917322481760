// <div className="archk-ai">

// <div className="archk-ai-blackout" />

// <div className="archk-ai-main">

// </div>

// </div>

.archk-ai {

    z-index: 2;

    h1,h2,h3,h4,h5,h6 {
        color: $white;
    }

    p, pre {
        color: darken($white, 20);
    }

    .archk-ai-blackout {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1000;
        background: rgba(0,0,0,.5);
    }

    .archk-ai-main {
        position: fixed;
        top: 0;
        right: 0;
        left: 200px;
        bottom: 0;
        z-index: 1050;
        background: #333;
        border-left: solid 1px #aaa;

        .archk-ai-main-left-sidebar {
            position: fixed;
            top: 62px;
            right: 0;
            left: 200px;
            bottom: 0;
            z-index: 2;
            background: #222;
            width: 200px;
            border-left: solid 1px #aaa;
            border-right: solid 1px #aaa;
            overflow: auto;

            .section {
                padding-left: 2rem;
                padding-right: 2rem;
                padding-top: .5rem;;
                padding-bottom: .5rem;;

                &.active {
                    background: #666;
                }

            }
        }

        .archk-ai-main-content {
            position: fixed;
            top: 62px;
            right: 0;
            left: 400px;
            bottom: 0;
            z-index: 2;

            .section {
                padding-left: 2rem;
                padding-right: 2rem;
                padding-top: .5rem;;
                padding-bottom: .5rem;;
            }

            .archk-ai-main-navigation {
                border-bottom: solid 1px #aaa;
            }

            .archk-main-content-thread {

                position: fixed;
                right: 0;
                top:110px;
                left: 400px;
                bottom: 61px;
                z-index: 2;
                background: #555;
                overflow: auto;

                padding-left: 2rem;
                padding-right: 2rem;
                padding-top: .5rem;;
                padding-bottom: .5rem;;

                .archk-main-content-message {
                    background: #222;
                    padding: 12px;
                    border-radius: 6px;

                    pre {
                        margin-bottom: 0;
                    }

                    &.archk-main-content-message-user {
                        background: #333;
                    }
                }
                
                &.archk-main-content-thread-empty {
                    background: linear-gradient(to bottom, #333, #888);
                }

                .archk-main-content-thread-textarea {
                    position: fixed;
                    right: 0;
                    left: 400px;
                    bottom: 0;
                    z-index: 2;
                    background: #222;
                    border-top: solid 1px #aaa;
                    padding: 1rem 2rem;

                    .archk-main-content-thread-textarea-send {
                        color: $white;
                        position: absolute;
                        right: .5rem;
                    }
                    .archk-main-content-thread-textarea-loading {
                        color: $white;
                        position: absolute;
                        right: -3px;
                        top: -5px;
                    }

                    .form-control {
                        border-radius: 2rem;
                        margin-bottom: 0;
                        padding-right: 40px;

                        background: #555;
                        color: $white;

                        padding-left: 1rem;

                        border: solid 1px darken($white, 30);


                        &:focus {
                            border: solid 1px $white;
                        }
                    }
        
                }

            }

        }

        .archk-ai-main-header {
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: solid 1px #aaa;

            background: #111;
        }

    }

}